export const shopConfigRoutes = [
    // {
    //     path: "/deviceConfig",
    //     name: "deviceConfig",
    //     meta: {
    //         title: "大屏配置",
    //         key: 'big-screen-config',
    //         pageApisPrm: import("@/views/shopConfig/deviceConfig/Index.vue").then(({ pageApiList }: any) => pageApiList)
    //     },
    //     component: () => import("@/views/shopConfig/deviceConfig/Index.vue"),
    // },
    {
        path: "/deviceConfig",
        name: "deviceConfig",
        meta: {
            title: "设备配置",
            key: 'device-config',
            pageApisPrm: import("@/views/shopConfig/deviceConfig/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/shopConfig/deviceConfig/Index.vue"),
    },
    {
        path: "/advertisementConfig",
        name: "advertisementConfig",
        meta: {
            title: "广告配置",
            key: 'advertisement-config',
            pageApisPrm: import("@/views/shopConfig/advertisementConfig/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/shopConfig/advertisementConfig/Index.vue"),
    },
]