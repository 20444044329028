
import {Component, Vue, Watch} from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import businessApi from "@/common/api/business";
import userCost from "@/components/userCost.vue";
import reservation from "@/components/reservation.vue";
import coach from "@/components/coach.vue";
import gods from "@/components/gods.vue";
import billPrint from "@/components/print.vue";
import mergePools from "@/views/business/business/mergePools.vue";
import cancelMergePools from "@/views/business/business/cancelMergePools.vue";
import goodSale from "@/views/business/business/goodSaleDialog.vue";
import goodsDispatchDetails from "@/views/business/business/goodsDispatchDetails.vue";
import guageEcharts  from './guageEcharts'
import serviceListDialog from '@/views/business/business/serviceListDialog.vue'
import moment from "moment";
import bus from "@/common/bus/bus";

export const pageApiList = getPageAPIs(businessApi);
const setIntervalTime = 10000
const default_coach_form={
  assistant_id:'',
  order_id:'',
  over_type:1,
  over_minute:''
}
@Component({
  components: {
    userCost,
    reservation,
    gods,
    coach,
    billPrint,
    mergePools,
    cancelMergePools,
    goodSale,
    goodsDispatchDetails,
    guageEcharts,
    serviceListDialog

  },
})
export default class TechnicianList extends Vue {
  private businessApi: any = new businessApi();
  private isLoading: boolean = false
  private title: string = "";
  private show_table: boolean = false;
  private invoicing: boolean = false;
  private pay: boolean = false;
  private scanCode: boolean = false;
  private openVisible: boolean = false;
  private setTimerVisible:boolean = false
  private groupBuyingOpeningVisible: boolean = false;
  private renewal: boolean = false;
  private printDialog: boolean = false;
  private tableDataTreat: any = [];
  private serviceAndConsume:number = 1
  private tableinfo:any = {}
  private showPayment:boolean = false
  private showCoach:boolean = false
  private opentable: any = {
    open_type: 2,
    pool_id: "",
    // time: 12,
    minute:'',
    phone: "",
    deposit_price: "",
    order_id: "",
  };
  private coachForm: any = {
    ...default_coach_form
  };
  private goods_visible:boolean = false
  private coach_visible:boolean = false
  private coach_stop_visible:boolean = false
  private all_stop_visible:boolean = false
  private order_visible:boolean = false
  private registe_visible:boolean = false
  private registe_form:any={
    remark:''
  }

  private pool_id: string = "";
  private coach_id: string = "";
  private orderlist: any = {
    order:[],
    goods:[],
    processing: {},
    merge_order:{}
  };
  private orderMoney: number = 0;
  private godsMoney: number = 0;
  private assistantrMoney: number = 0;
  private totalConsumeMoney : number = 0
  //消费清单列表
  private consumeList:any[] = []

  //当前时间
  private currentTime:string = ''
  private shopInfo:any = {}
  mounted() {
    this.getTable();
    this.getGuageData()
    this.currentTime = moment().format('YYYY-MM-DD HH:mm')
    this.shopList()
    bus.$on('pendant-pay',(payload:any)=>{
      console.log('bus参数',payload)
      this.pool_id = payload.pool_id
      this.order_id = payload.order_id
      this.guadan_to_pay()
    })
    bus.$on('home-service',()=>{
      this.$refs.serviceListDialog.show()
    })
  }

  private activated(): void {
    // this.getTable();
    this.startCountDown();
    this.startCountConsumeList();
    console.log('组件实例激活。');
  }

  deactivated () {
    // 实例销毁之前调用。主要用于销毁时需要清理的事件监听器、定时器等
    console.log('组件实例即将销毁。');
    clearInterval(this.setInterval)
    clearInterval(this.setInterConsumeList)

  }

  private order_id: string = "";
  private orderindex() {
    this.businessApi.orderindex({pool_id: this.pool_id,},
        (res: any) => {
          // this.show_table = true;
          this.orderlist = res.data;
          this.order_id = this.orderlist.processing.id;
          this.orderMoney = 0;
          this.godsMoney = 0;
          this.assistantrMoney = 0;
          for(let i of this.orderlist.order){
            this.orderMoney += Number(i.price)
          }
          for (let i of this.orderlist.goods) {
            this.godsMoney += Number(i.actual_price);
          }
          for (let i of this.orderlist.assistant) {
            this.assistantrMoney += Number(i.total_price);
          }
          this.totalConsumeMoney = Number(this.orderMoney) + Number(this.godsMoney) + Number(this.assistantrMoney)
          this.totalConsumeMoney =  parseFloat(this.totalConsumeMoney.toFixed(2))
          const { order:pool ,assistant:coach,goods } = res.data
          pool.forEach((item:any)=>{
            item['consume_type'] = 'pool'
          })
          coach.forEach((item:any)=>{
            item['consume_type'] = 'coach'
          })
          goods.forEach((item:any)=>{
            item['consume_type'] = 'goods'
          })
          this.consumeList = [...pool, ...coach, ...goods];
        }, () => {
        }, true);
  }
  private onConfirmGoods():void{
    const item = this.currentListItem
    this.businessApi.goodsSalecart(
        {
          // cart_goods: gods,
          order_id: item.order_id,
          delete_good_ids: item.id,
          desk_id:item.desk_id
        },
        () => {
          Util.showToast("删除成功");
          this.goods_visible = false
          this.orderindex()
        }
    );
  }

  private onConfirmCoach():void{
    const item = this.currentListItem
    this.businessApi.deleteAssistant(
        {
          id: item.id,
          order_id:this.order_id,
        },
        () => {
          Util.showToast("删除成功");
          this.coach_visible = false
          this.orderindex()
          this.getAssistantList();
        }
    );
  }

  private onConfirmStopCoach():void{
    const item = this.currentListItem
    this.businessApi.assistantover(
        {assistant_order_id: item.id,},
        () => {
          Util.showToast("结算成功");
          this.coach_stop_visible = false
          this.orderindex()
          this.getAssistantList();
        }
    );
  }

  private onConfirmStopAll(): void {
    this.clearOfferPrice()
    this.businessApi.overorder(
        {
          order_id: this.order_id,
        },
        (res: any) => {
          console.log('跳转到结算', res.data)
          const {is_lock, total_price} = res.data
          //已抹零，直接显示结账弹窗
          if (is_lock === 1) {
            this.all_stop_visible = false

            this.overorder()
          } else {
            this.target_should_offer_price = Number(total_price) //参考值
            this.payInfo = res.data
            this.orderindex()
            this.all_stop_visible = false
            this.showPayment = true
          }
        },(err:any)=>{
          if(err.code===405){
            this.order_visible = true
          }
        }
    );
  }

  private guadan_to_pay(): void {
    this.clearOfferPrice()
    this.businessApi.overorder(
        {
          order_id: this.order_id,
        },
        (res: any) => {
          console.log('跳转到结算', res.data)
          const {is_lock, total_price} = res.data
          clearInterval(this.setInterConsumeList)
          //已抹零，直接显示结账弹窗
          if (is_lock === 1) {
            this.all_stop_visible = false
            this.overorder()
          } else {
            this.target_should_offer_price = Number(total_price) //参考值
            this.payInfo = res.data
            this.checkout()
            this.all_stop_visible = false
            this.showPayment = true
          }
        },(err:any)=>{
          if(err.code===405){
            this.order_visible = true
          }
        }
    );
  }

  private onConfirmTransferOrder(){
    this.businessApi.changeOrder({
      order_id:this.orderlist.processing.id
    },((res:any)=>{
      Util.showToast("转化成功");
      this.order_visible = false
    }))
  }

  //直接开台
  private onBoot():void{
    this.businessApi.orderboot({
      open_type:2,
      pool_id:this.pool_id,
    }, () => {
      Util.showToast("开台成功");
      this.getTable();
      this.orderindex()
    });
  }

  //跳转到结账页面
  private toPayment():void{
    this.all_stop_visible = true

  }




  private chargebill: any = {};
  private good_list: any = [];

  private charge(): void {

    this.businessApi.charge(
        {
          merge_order_id: this.payInfo.merge_order_id,
        },
        (res: any) => {
          this.chargebill = res.data;
          this.good_list = res.data.good_list;
          this.tableDataTreat = [
            {
              name: "原始 - 台桌订单费用",
              money: res.data.order_price,
            },
            {
              name: "原始 - 商品订单费用",
              money: res.data.good_price,
            },
            {
              name: "原始 - 助教订单费用",
              money: res.data.assistant_price,
            },
            {
              name: "原始 - 总费用",
              money: res.data.total_price,
            },
            {
              name: "实际支付 - 台桌订单费用",
              money: res.data.actual_order_price,
            },
            {
              name: "实际支付 - 商品订单费用",
              money: res.data.actual_good_price,
            },
            {
              name: "实际支付 - 助教订单费用",
              money: res.data.actual_assistant_price,
            },
            {
              name: "实际支付 - 总费用",
              money: res.data.actual_total_price,
            },
          ];
          this.printDialog = true;
        }
    );
  }

  private cost: string = "";
  private payInfo: any = {};

  //原结算接口
  private overorder(): void {
    console.log('this.order_id',this.order_id)
    this.mobileOptions = []
    this.is_vip_pay = 0
    this.businessApi.overorder(
        {
          order_id: this.order_id,
        },
        (res: any) => {
          console.log('结算', res.data)
          this.pay = true;
          this.userinfo.id = "";
          this.msg = "";
          this.show_table = false;
          this.invoicing = false;
          this.payInfo = res.data;
        }
    );
  }


  //抹零结算后显示结账弹窗
  private checkoutAndShowDialog():void{
    const offer_price = parseFloat(this.offer_fen_price || 0)
        + parseFloat(this.offer_jiao_price|| 0 )
        + parseFloat(this.offer_kuai_price || 0)
    const order_offer_price = this.discountMode===1?(this.offer_shoudong_price || 0):(this.offer_zhekou_price || 0)
    console.log('offer_price',offer_price)
    this.businessApi.offer({
      merge_order_id:this.payInfo.merge_order_id,
      offer_price:offer_price,
      order_offer_price:order_offer_price
    }, (res: any) => {
      console.log('抹零后的结果', res)
      this.overorder()
    })
  }

  private refundDiscountVisible:boolean = false
  private refundDiscountMode:string = ''

  //取消抹零
  private refundDiscount(mode:string=''){
    this.refundDiscountMode = mode
    this.refundDiscountVisible = true
  }

  //确定取消抹零
  private onComfirmRefundDiscount():void{
    switch (this.refundDiscountMode) {
      case 'fen':
        // this.should_pay_merge_price = Number(this.should_pay_merge_price) + Number(this.offer_fen_price || 0)
        this.offer_fen_price = ''
        break
      case 'jiao':
        // this.should_pay_merge_price = Number(this.should_pay_merge_price) + Number(this.offer_jiao_price || 0)
        this.offer_jiao_price = ''
        break
      case 'kuai':
        // this.should_pay_merge_price = Number(this.should_pay_merge_price) + Number(this.offer_kuai_price || 0)
        this.offer_kuai_price = ''
        break
      case 'shoudong':
        // this.should_pay_merge_price = Number(this.should_pay_merge_price) + Number(this.offer_shoudong_price || 0)
        this.offer_shoudong_price = ''
        break
      case 'zhekou':
        // this.should_pay_merge_price = Number(this.should_pay_merge_price) + Number(this.offer_zhekou_price || 0)
        this.offer_zhekou_price = ''
        break
    }
    this.refundDiscountVisible = false



  }


  private orderinfo: any = {};

  private orderboot(type: number): void {
    var reg_phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    // if (this.opentable.phone != "") {
    //   if (!reg_phone.test(this.opentable.phone)) {
    //     Util.showToast("请输入正确的手机号", "warning");
    //     return;
    //   }
    // }
    // if (!(this.opentable.minute >= 1 && this.opentable.minute <= 48)) {
    //   Util.showToast("时长范围为1-48小时", "warning");
    //   return;
    // }
    this.biz_type = type;
    if (this.tableinfo.is_status == 2 || this.tableinfo.is_status == 3) {
      this.opentable.order_id = this.order_id;
    }
    this.businessApi.ordercheck(
        {
          ...this.opentable,
          pool_id: this.pool_id,
        },
        (res: any) => {
          if (res.data.isOver == 0) {
            this.opentable.pool_id = this.pool_id;
            this.businessApi.orderboot(this.opentable, (res: any) => {
              this.order_id = res.data.order_id;
              this.orderinfo = res.data;
              this.renewal = false;
              this.openVisible = false
              this.show_table = false;
              this.setTimerVisible = false
              this.getTable();
              this.orderindex()
            });
          } else if (res.data.isOver == 3) {
            // Util.showToast("暂无可约时间");
            Util.showToast("此球桌已被占用，请选择其他球桌");
          } else if (res.data.isOver == 1) {
            // Util.showToast("预约时间被占用");
            Util.showToast("此球桌已被占用，请选择其他球桌");
          }
        }
    );
  }

  private code: string = "";
  private is_vip_pay: number = 0;

  private orderpay(print = false): void {
    console.log('手机号码', this.opentable.phone, this.is_vip_pay)
    //会员支付
    if (this.opentable.phone.length != 11 && this.check === 5) {
      Util.showToast("请输入正确的手机号点击查询再结账", "warring");
      return
    }
    //微信和支付宝-组合支付
    if (this.check === 1 && this.is_vip_pay > 0 && this.opentable.phone.length != 11) {
      Util.showToast("请输入正确的手机号点击查询再结账", "warring");
      return
    }

    if (this.check == 3 || this.check == 5 || this.check == 6) {
      if (
          this.payInfo.total_price > this.payInfo.order_price && this.check == 5
      ) {
        Util.showToast("有其他费用，请使用组合支付", "warring");
      } else {
        if (this.isLoading) {
          return;
        }
        this.isLoading = true
        this.businessApi.orderpay(
            {
              merge_order_id: this.payInfo.merge_order_id,
              pay_type: this.check,
              is_vip_pay: (this.check === 3 || this.check === 6) ? 0 : this.is_vip_pay,
              user_id: this.userinfo.id,
              // code: this.code,
            },
            (res: any) => {
              Util.showToast("预支付成功");
              if (print) {
                this.charge();
              }
              this.userinfo.id = "";
              this.msg = "";
              this.renewal = false;
              this.show_table = false;
              this.pay = false;
              this.code = "";
              this.invoicing = false;
              this.is_vip_pay = 0;
              this.tableinfo = {}
              this.pool_id = ''
              this.order_id = ''
              this.showPayment = false
              bus.$emit('pendant-reload')
              setTimeout(() => {
                this.isLoading = false
              }, 300)
              this.getTable();
              this.getGuageData()
            }, () => {
              setTimeout(() => {
                this.isLoading = false
              }, 300)
            }
        );
      }
    } else {
      this.scanCodePay(print);
    }
  }

  private topStatus(status: number): string {
    return `top${status}`
  }


  private auth_code: string = "";
  private biz_type: number = 3;

  private payrequest(print = false): void {
    this.businessApi.payrequest(
        {
          order_id: this.payInfo.merge_order_id,
          pay_type: this.check,
          biz_type: this.biz_type,
          auth_code: this.auth_code,
          is_vip_pay: this.is_vip_pay,
          user_id: this.userinfo.id,
          // code: this.code,
        },
        (res: any) => {
          if (print) {
            this.charge();
          }
          Util.showToast("支付成功");
          this.is_vip_pay = 0;
          this.code = "";
          this.renewal = false;
          this.show_table = false;
          this.pay = false;
          this.invoicing = false;
          this.scanCode = false;
          this.pool_id=''
          this.order_id=''
          this.showPayment = false
          this.getTable();
          this.getGuageData()
        }
    );
  }

  private handleClose(done: any) {
    if (this.tableinfo.is_status == 1) {
      this.cancelpay();
    } else {
      done();
      this.getTable();
      // this.$confirm("确认关闭？")
      //     .then((_) => {
      //       done();
      //       this.getTable();
      //     })
      //     .catch((_) => {
      //     });
    }
  }

  private getscanCode(print = false): void {
    let that = this;
    let str = "";
    document.onkeydown = function (e: any) {
      if (e.target.tagName !== "BODY") {
        str = "";
      } else {
        if (e.keyCode !== 13) {
          if (e.keyCode >= 32 && e.keyCode <= 126) {
            let k = e.key;
            str += k;
          }
        } else {
          if (str) {
            that.auth_code = str;
            str = "";
            that.payrequest(print);
          }
        }
      }
    };
  }

  private payquery(): void {
    this.businessApi.payquery(
        {
          order_id: this.payInfo.merge_order_id,
          biz_type: this.biz_type,
        },
        (res: any) => {
          if (res.code == 200) {
            Util.showToast("支付成功");
            this.code = "";
            this.renewal = false;
            this.show_table = false;
            this.pay = false;
            this.scanCode = false;
            this.invoicing = false;
            this.pool_id = ''
            this.order_id = ''
            this.showPayment = false
            this.getTable();
            this.getGuageData()
          }
        }
    );
  }

  private cancelpay(): void {
    console.log(this.opentable);
    this.businessApi.cancelpay(
        {
          order_id: this.order_id,
        },
        () => {
          Util.showToast("取消成功");
          this.renewal = false;
          this.show_table = false;
          this.pay = false;
          this.scanCode = false;
          this.getTable();
        }
    );
  }

  private pay_type: any = [
    {
      check: 1,
      name: "微信和支付宝",
      icon: require("@/assets/dialog/weixin.png"),
    },
    // {
    //   check: 4,
    //   name: "会员",
    //   icon: require("@/assets/dialog/huiyuan.png"),
    // },
    {
      check: 5,
      name: "会员",
      icon: require("@/assets/dialog/huiyuan.png"),
    },
    {
      check: 6,
      name: "现金",
      icon: require("@/assets/dialog/xianjin.png"),
    },
    {
      check: 3,
      name: "线下收款码",
      icon: require("@/assets/dialog/shoukuanma.png"),
    },
  ];
  private check: number = 1;

  private changePayType(checkType: number): void {
    this.check = checkType;
  }

  private scanCodePay(print = false): void {
    this.scanCode = true;
    this.getscanCode(print);
  }


  private is_lock:number = 0
  private checkout(): void {
    this.businessApi.orderBill({order_id: this.order_id},
        (res: any) => {
          this.is_lock = res.data.merge_order.is_lock || 0
          this.orderMoney = 0;
          this.godsMoney = 0;
          this.assistantrMoney = 0;
          const { order:pool ,assistant:coach,goods } = res.data
          for(let i of pool){
            this.orderMoney += Number(i.price)
          }
          for (let i of goods) {
            this.godsMoney += Number(i.actual_price);
          }
          for (let i of coach) {
            this.assistantrMoney += Number(i.total_price);
          }
          this.totalConsumeMoney = Number(this.orderMoney) + Number(this.godsMoney) + Number(this.assistantrMoney)
          this.totalConsumeMoney =  parseFloat(this.totalConsumeMoney.toFixed(2))

          pool.forEach((item:any)=>{
            item['consume_type'] = 'pool'
          })
          coach.forEach((item:any)=>{
            item['consume_type'] = 'coach'
          })
          goods.forEach((item:any)=>{
            item['consume_type'] = 'goods'
          })
          this.consumeList = [...pool, ...coach, ...goods];
        },(err:any)=>{
          console.log('err',err)

        }
    );
  }



  private tableView(item: any): void {
    console.log(item);
    if(this.pool_id===item.id){
      this.pool_id = ''
      this.order_id = ''
      return
    }
    this.checkUsePools = [];
    this.opentable.phone = "";
    this.pool_id = item.id;
    this.order_id = item.order_id
    this.title = item.name;
    this.tableinfo = {...item}
    this.clearOfferPrice()
    this.orderindex();
  }
  private currentEnterIndex:any = ''
  private onMouseenterItem(index:number):void{
    this.currentEnterIndex = index;
  }
  private onMouseleaveItem():void{
    this.currentEnterIndex = '';
  }


  private currentCoach:any = {}
  private coach_select_visible:boolean = false
  //点击选择助教
  private onSelectCoach(item:any){
    console.log(item)
    this.currentCoach = {...item}
    this.coachForm = {
      ...default_coach_form
    }
    this.coach_select_visible = true
  }

  //助教下单
  private assistantorder(): void {
    this.coachForm.order_id = this.orderlist.processing.id;
    this.coachForm.assistant_id = this.currentCoach.id
    console.log(this.coachForm);
    this.businessApi.assistantorder(this.coachForm, () => {
      Util.showToast("下单成功");
      this.coach_select_visible = false;
      this.orderindex();
    });
  }

  private guage_assistant:any = {}
  private guage_desk:any = {}
  private guageLoading:boolean = false
  private getGuageData():void{
    this.businessApi.meter({}, (res: any) => {
      console.log('仪表盘数据：',res.data)
      const {assistant,desk} = res.data
      this.guage_assistant = assistant
      this.guage_desk = desk
      this.guageLoading = true
    });
  }

  /**
   * @param {number or string} value 数值
   * @param {Number} digits 保留小数位数
   * @param {bool} returnStr 返回值是字符串（如果保留位数那么返回是一个string）
   */

  //加减商品
  private addOrminusGood(item:any,index:number,type:string):void{
    console.log('商品',item)
    let num = item.num
    num = type==='add'?++num:--num
    let goods = `${item.goods_id}=${num}`
    this.businessApi.goodsSalecart(
        {
          order_id: this.order_id,
          cart_goods: goods,
          desk_id:this.pool_id,
        },
        () => {
          Util.showToast("操作成功");
          // this.consumeList[index].num = num
          this.orderindex()
        }
    );
  }

  //写法一：删除列表里面的商品
  private deleteGoods(item: any): void {
    console.log(item)
    this.businessApi.goodsSalecart(
        {
          // cart_goods: gods,
          order_id: item.order_id,
          delete_good_ids: item.id,
          desk_id:item.desk_id
        },
        () => {
          Util.showToast("删除成功");
          this.orderindex()
        }
    );
  }
  //写法二：点击列表选择后，再删除商品
  private deleteSelectGoodsOrCoach():void{
    const item = this.currentListItem
    if(item.consume_type==='goods'){
      this.goods_visible = true
      return
    }
    if(item.consume_type==='coach'){
      this.coach_visible = true
      return
    }
   
  }
  
  //删除助教
  private deleteAssistant(item:any): void {
    console.log(item)
    this.businessApi.deleteAssistant(
        {
          id: item.id,
          order_id:this.order_id,
        },
        () => {
          Util.showToast("删除成功");
          this.orderindex()
        }
    );
  }

  //结算助教订单
  private assistantover(item: any): void {
    this.businessApi.assistantover(
        {assistant_order_id: item.id,},
        () => {
          Util.showToast("结算成功");
          this.orderindex()
        }
    );
  }

  //写法二：点击列表选中后，再结算助教订单
  private stopSelectGoods():void{
    const item = this.currentListItem
    this.coach_stop_visible = true
  }

  private reloadConsumeList():void{
    this.orderindex()
    this.$message.success('刷新成功')
  }

  private setInterval: any = null
  private setInterConsumeList: any = null
  private startCountDown() {
    this.setInterval = setInterval(() => {
      this.getTable()
      // this.getAllTable()
      this.currentTime = moment().format('YYYY-MM-DD HH:mm')
    }, setIntervalTime);
  }
  private startCountConsumeList() {
    this.setInterConsumeList = setInterval(() => {
      if(this.pool_id&&this.order_id){
        this.orderindex()
      }
    }, setIntervalTime);
  }

  private getTable(): void {
    this.businessApi.pool({type:this.poolTableStatus}, (res: any) => {
      this.tableData = res.data.list;
      this.pool_stat = res.data.pool_stat
      // this.getOrderGoodsSale()
    });
  }

  private allTableData:any[] = []
  private getAllTable():void{
    this.businessApi.pool({type:0}, (res: any) => {
      this.allTableData = res.data.list;
    });
  }

  private getPoolTableStateNum(type:number):number{
    let num1:number = 0
    let num2:number = 0
    let num3:number = 0
    this.allTableData.forEach((item:any)=>{
      if(item.is_status===1){
        num1++
      } else if(item.is_status===2){
        num2++
      } else  if(item.is_status===3){
        num3++
      }
    })
    if(type==1){
      return num1
    }
    if(type==2){
      return num2
    }
    if(type==3){
      return num3
    }
    return 0
  }

  /**
   * 选择消费清淡列表
   * */
  private currentListIndex:number = -1
  private currentListItem:any = {}
  private selectListItem(item:any,index:number){
    this.currentListIndex = index
    this.currentListItem = item
  }
  //是否可删除操作
  private isEnableDeleteListItem(){
    if(!(this.currentListIndex>-1)){
      return false
    }
    if(this.orderlist.merge_order.is_lock===1){
      return false
    }
    const { consume_type }  = this.currentListItem
    if(consume_type === 'goods' || consume_type === 'coach'){
      return true
    }
    return false
  }

  //是否可停止操作
  private isEnableStopListItem(){
    if(!(this.currentListIndex>-1)){
      return false
    }
    if(this.orderlist.merge_order.is_lock===1){
      return false
    }
    const { consume_type }  = this.currentListItem
    if(consume_type === 'coach'){
      return true
    }
    return false
  }

  //切换球桌列表状态
  private changePoolTable(status:number):void{
    this.poolTableStatus = status
    this.getTable()
  }

  private orderGoodsSaleList: any = []

  private getOrderGoodsSale() {
    this.businessApi.orderGoodsSale({}, (res: any) => {
      this.orderGoodsSaleList = res.data.list
      const len = this.tableData.length
      for (const item of this.orderGoodsSaleList) {
        for (let i = 0; i < len; i++) {
          const table = this.tableData[i]
          if (item.desk_id == table.id) {
            table.goods_sale_num = item.num
            table.goods_sale_created_at = item.created_at
            this.$set(this.tableData, i, table)
          }
        }
      }
    });
  }

  private onShowGoodsDispatch(table: any): void {
    this.$refs.goodsDispatch.show(table)
  }

  private restForm(): void {
    this.opentable = new Util().clearObject(this.opentable);
    // this.opentable.minute = 12;
    this.opentable.open_type = 2
  }

  private tableData: any[] = [];
  private pool_stat:any = {
    idle_num: 9,
    proceed_num: 1,
    stop_num : 1,
    total : 11,
  }
  private poolTableStatus:number = 0
  private filterTableData():any[] {
    if(this.poolTableStatus===0){
      return  this.tableData
    }else{
      return this.tableData.filter((item:any)=>item.is_status===this.poolTableStatus) || []
    }

  }

  private coachshow: boolean = false;

  private selectcoach(): void {
    // this.coachshow = true;
    this.showCoach = true
    this.getAssistantList();
  }

  private msg: string = "";
  private userinfo: any = {
    type: 0,
  };

  //续费确定
  private onConfirmRenewal(): void {
    console.log('续费', this.orderlist.processing.id, this.opentable.minute)
    this.businessApi.orderRenew({
      order_id: this.orderlist.processing.id,
      minute: this.opentable.minute
    }, (res: any) => {
      Util.showToast("续费成功");
      this.orderindex()
      this.getTable()
      this.renewal = false
    })
  }

  //会员查询
  private searchmember(): void {
    this.msg = "";
    this.businessApi.searchmember(
        {mobile: this.opentable.phone,},
        (res: any) => {
          console.log('查询用户信息', res)
          this.userinfo = res.data;
          if (res.data.nickname) {
            if (res.data.type != 0) {
              if (this.$store.state.platformType === 1) {
                this.msg = `用户${res.data.nickname}会员余额：￥${res.data.money},单店卡：￥${res.data.shop_money}`;
              } else {
                this.msg = `用户${res.data.nickname}单店卡：￥${res.data.shop_money}`;
              }
            } else {
              if (res.data.shop_money != 0) {
                this.msg = `用户${res.data.nickname}单店卡：￥${res.data.shop_money}`;
              } else {
                this.msg = "该用户还不是会员";
              }
            }
          } else {
            this.msg = "未查询到此用户";
          }
        }
    );
  }


  private assistantLevel:number = 1
  //切换助教列表列表状态
  private changeAssistantList(status:number):void{
    this.assistantLevel = status
    this.getAssistantList()
  }

  //助教列表
  private assistantList: any = [];
  private assistant_level:any = {
    level_advanced_coach_num: 0,
    level_middle_coach_num: 0,
    level_primary_coach_num : 0,
    level_internship_coach_num:0,
    level_star_level_coach_num:0,
    level_star_coach_num:0
  }
  private getAssistantList(): void {
    this.businessApi.assistantshow({level:this.assistantLevel}, (res: any) => {
      const {
        level_advanced_coach_num,
        level_middle_coach_num,
        level_primary_coach_num,
        level_internship_coach_num,
        level_star_level_coach_num,
        level_star_coach_num
      } = res.data
      this.assistantList = res.data.list;
      this.assistant_level.level_advanced_coach_num = level_advanced_coach_num
      this.assistant_level.level_middle_coach_num = level_middle_coach_num
      this.assistant_level.level_primary_coach_num = level_primary_coach_num
      this.assistant_level.level_internship_coach_num = level_internship_coach_num
      this.assistant_level.level_star_level_coach_num = level_star_level_coach_num
      this.assistant_level.level_star_coach_num = level_star_coach_num
    });
  }

  //转台
  private poolshow: boolean = false;
  private poolist: any = [];
  private poolsform: any = {
    pool_id: null
  };

  private changePools(): void {
    this.businessApi.changePools(
        {
          pool_id: this.pool_id,
        },
        (res: any) => {
          this.poolist = res.data.list;
        }
    );
  }

  private orderchange(): void {
    this.businessApi.orderchange(
        {
          // order_id: this.showinfo.id,
          order_id: this.orderlist.processing.id,
          pool_id: this.poolsform.pool_id,
        },
        () => {
          this.poolsform.pool_id = "";
          Util.showToast("转台成功");
          this.poolshow = false;
          this.show_table = false;
          this.getTable();
        }
    );
  }

  private checkUsePools: any = [];
  private processing_merge: any = [];
  private assistant_merge: any = [];
  private goods_merge: any = [];


  private getShouldPayPrice(): any {
    const target_should_offer_price = Number(this.target_should_offer_price)
    const offer_fen_price = Number(this.offer_fen_price)
    const offer_jiao_price = Number(this.offer_jiao_price)
    const offer_kuai_price = Number(this.offer_kuai_price)
    const offer_shoudong_price = Number(this.offer_shoudong_price)
    const offer_zhekou_price = Number(this.offer_zhekou_price)
    const discount_price = this.discountMode === 1 ? offer_shoudong_price : offer_zhekou_price
    let should_pay_merge_price = target_should_offer_price - offer_fen_price - offer_jiao_price - offer_kuai_price - discount_price
    should_pay_merge_price = should_pay_merge_price>=0?should_pay_merge_price:0
    return  should_pay_merge_price.toFixed(2)
  }

  //优惠金额
  private getAllDiscountPrice(): any {
    const offer_fen_price = Number(this.offer_fen_price)
    const offer_jiao_price = Number(this.offer_jiao_price)
    const offer_kuai_price = Number(this.offer_kuai_price)
    const offer_shoudong_price = Number(this.offer_shoudong_price)
    const offer_zhekou_price = Number(this.offer_zhekou_price)
    const discount_price = this.discountMode === 1 ? offer_shoudong_price : offer_zhekou_price
    const all_discount_price =   offer_fen_price + offer_jiao_price + offer_kuai_price + discount_price
    return  all_discount_price.toFixed(2)
  }

  private getAllDiscountPriceByOrder(merge_order:any):any{
    let sum = 0
    sum = Number(merge_order.order_offer_price) + Number(merge_order.offer_price)
    return sum.toFixed(2)
  }

  private processing_merge_price: number = 0; //台费合计
  private assistant_merge_price: number = 0; //助教合计
  private goods_merge_price: number = 0; //商品合计
  private target_should_offer_price:any = '' //需要抹零的参考值
  private offer_fen_price:any = '' //抹分金额
  private offer_jiao_price:any = '' //抹角金额
  private offer_kuai_price:any = '' //抹块金额
  private offer_shoudong_price:any = '' //手动优惠
  private temp_offer_shoudong_price:any = '' //暂时存储，点击确定后赋值
  private offer_zhekou_price:any = '' //折扣优惠
  private offer_zhekou_rate:any = '' //折扣绿
  private temp_offer_zhekou_rate:any = '' //折扣绿

  private showDiscount:boolean = false





  private onChangePool(): void {
    this.poolshow = true
    this.poolsform.pool_id = null
    this.changePools()
  }


  private onMergePools(): void {
    let orderInfo = this.orderlist.processing || {}
    this.$refs.mergePools.show(this.pool_id, orderInfo)
  }

  private onCancelMergePools(): void {
    let orderInfo = this.orderlist.processing || {}
    this.$refs.cancelMergePools.show(this.pool_id, orderInfo)
  }

  private classify: any = [];
  private classifys(): void {
    this.businessApi.classifys({}, (res: any) => {
      this.classify = res.data.list;
      this.msg = "";
    });
  }

  /**
   * 停止订单
   * */

  private onPauseOrder():void{
    this.businessApi.orderPause({ order_id: this.order_id,}, (res: any) => {
      console.log('停止结果',res)
      this.$message.success('停止成功')
      this.orderindex()
    });
  }


  /**
   * 订单恢复计费
   * */

  private onResumeOrder():void{
    this.businessApi.orderResume({ order_id: this.order_id,}, (res: any) => {
      console.log('恢复计费',res)
      this.$message.success('恢复成功')
      this.orderindex()
    });
  }


  /**
   * 挂单
   * */
  private onRegiste():void{
    this.registe_form.remark = ''
    this.registe_visible = true
  }

  private onConfirmRegiste():void{
    this.businessApi.orderPendant({
      order_id: this.order_id,
      remark:this.registe_form.remark
    }, (res: any) => {
      console.log('挂单结果',res)
      this.$message.success('挂单成功')
      bus.$emit('pendant-reload')
      this.orderindex()
      this.registe_visible = false
    });

  }

  private totalConsumptionMoney(): any {
    let total = 0
    if (this.orderlist.order && this.orderlist.order.length) {
      this.orderlist.order.forEach((item: any) => {
        total += Number(item.price)
      })
      total = total + Number(this.godsMoney) + Number(this.assistantrMoney)
      return total.toFixed(2)
    }
    return total.toFixed(2)
  }

  private onShowGoodsSale(): void {
    this.$refs.goodSale.show(this.orderlist.processing.id, this.tableinfo)
  }

  private goodSaleCallback(): void {
    this.orderindex();
  }

  private goodsDispatchCallback(): void {
    this.getTable();
  }

  /**
   * 并台成功
   * */
  private onConfirmGroupBuying(): void {
    const order_open_classify_id = this.opentable.order_open_classify_id
    if (!order_open_classify_id) {
      Util.showToast('券码必填！', 'warning')
      return
    }
    this.biz_type = 3;
    this.businessApi.verifyCard({
      receipt_code: order_open_classify_id,
      type: 0
    }, (verifyResponse: any) => {
      console.log('预核销结果', verifyResponse)
      const result = verifyResponse.data
      const cardTypeName = result.type === 3 ? '美团券' : (result.type === 4 ? '抖音券' : '')
      this.$confirm(`您已选择“${result.name}”，时长${result.value}分钟是否核销开台`, `${cardTypeName}核销提示`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.businessApi.ordercheck(
            {
              pool_id: this.pool_id,
              minute: result.value
            }, (res: any) => {
              if (res.data.isOver == 0) {
                this.businessApi.verifyCard({
                  receipt_code: order_open_classify_id,
                  type: 1
                }, (res2: any) => {
                  console.log('核销成功', res2)
                  this.groupBuyingOpeningVisible = false;
                  this.businessApi.orderboot({
                    pool_id: this.pool_id,
                    open_type: result.type,
                    order_open_classify_id: res2.data.order_open_classify_id
                  }, (res: any) => {
                    this.order_id = res.data.order_id;
                    this.orderinfo = res.data;
                    this.show_table = false;
                    this.getTable();
                    this.orderindex()
                  });
                })
              } else if (res.data.isOver == 1 || res.data.isOver == 3) {
                // Util.showToast("暂无可约时间");
                Util.showToast("此球桌已被占用，请选择其他球桌");
              }
            }
        )


      })
    })

  }

  /**
   * 分钟转小时，保留两位小数点
   * */
  private minutesToHours(minutes: any) {
    var hours = minutes / 60;
    var factor = Math.pow(10, 2);
    var result = Math.floor(hours * factor) / factor;
    return result;
  }

  /**
   * 并台成功
   * */
  private mergePoolsSuccess(): void {
    this.show_table = false
    this.getTable()
  }

  /**
   * 取消并台成功
   * */
  private cancelMergePoolsSuccess(): void {
    this.getTable()
    this.orderindex()
  }

  private mobileOptions: any = [];
  private remoteLoading: boolean = false

  private remoteMethod(query: any): void {
    this.opentable.phone = query
    if (query.length == 4 || query.length == 11) {
      this.remoteLoading = true
      this.businessApi.searchMobile({mobile: query}, (res: any) => {
        this.remoteLoading = false
        console.log('手机号结果', res)
        this.mobileOptions = res.data.list
      })
      if (query.length == 11) {
        this.searchmember()
      }
    } else {
      this.mobileOptions = [];
    }
  }

  private onSelectMobile(mobile: string): void {
    this.searchmember()
  }

  //点击抹零
  private earseMoney(type:string): void {
    console.log(this.payInfo)
    if(this.payInfo.is_lock===1){
      this.$message.warning('订单已抹零过，无法继续抹零')
      return;
    }
    let discountMoney = this.discountMode === 1 ? (this.offer_shoudong_price || 0) : (this.offer_zhekou_price || 0)
    console.log('discountMoney',discountMoney)
    console.log('this.target_should_offer_price',this.target_should_offer_price)
    const amount  =  Number(this.target_should_offer_price) - Number(discountMoney)
    if(type==='fen'){
      if(this.offer_fen_price){
        this.refundDiscountMode = type
        this.refundDiscountVisible = true
        return
      }
      console.log('9999999999999999999999999',this.getPriceComponents(amount))
      const fen = this.getPriceComponents(amount).fen; // 四舍五入到最近的分
      this.offer_fen_price = fen
      // this.should_pay_merge_price = Number(this.should_pay_merge_price) - Number(this.offer_fen_price)
      return;
    }
    if(type==='jiao'){
      if(this.offer_jiao_price){
        this.refundDiscountMode = type
        this.refundDiscountVisible = true
        return
      }
      const jiao = this.getPriceComponents(amount).jiao; // 四舍五入到最近的分
      this.offer_jiao_price = jiao
      // this.should_pay_merge_price = Number (this.should_pay_merge_price) - Number(this.offer_jiao_price)
      return;
    }
    if(type==='kuai'){
      if(this.offer_kuai_price){
        this.refundDiscountMode = type
        this.refundDiscountVisible = true
        return
      }
      const kuai = this.getPriceComponents(amount).kuai; // 四舍五入到最近的分
      this.offer_kuai_price = kuai
      // this.should_pay_merge_price = Number(this.should_pay_merge_price)  - Number(this.offer_kuai_price)
      return;
    }
  }

  private discountMode:any = ''
  private changeDiscountMode(mode:any=''):void{
    if(this.discountMode===mode&&this.offer_shoudong_price){
      this.refundDiscountMode = 'shoudong'
      this.refundDiscountVisible = true
      return
    }
    if(this.discountMode===1&&mode===2&&this.offer_shoudong_price){
      this.$message.warning('请点击金额撤销手动优惠,手动优惠和折扣优惠只能选一个')
      return
    }
    if(this.discountMode===mode&&this.offer_zhekou_price){
      this.refundDiscountMode = 'zhekou'
      this.refundDiscountVisible = true
      return
    }
    if(this.discountMode===2&&mode===1&&this.offer_zhekou_price){
      this.$message.warning('请点击金额撤销折扣优惠,手动优惠和折扣优惠只能选一个')
      return
    }
    this.discountMode = mode
    this.showDiscount = true
  }

  //手动输入优惠金额
  private inputShoudongPrice(value){
    // 使用正则表达式来替换非数字和非小数点的字符
    let formattedValue = value.replace(/[^\d.]/g, '');
    // 限制只能输入两位小数
    formattedValue = formattedValue.replace(/\.{2,}/g, '.');
    formattedValue = formattedValue.replace(/(\.\d{2}).+/g, '$1');
    // 确保小数点只出现一次，且在第一位不是小数点的情况下
    formattedValue = formattedValue.replace(/^\./g, '');
    // 如果输入的是整数，则前面不需要加点
    formattedValue = formattedValue.replace(/^0+(\d)/g, '$1');
    // 更新数据模型
    this.temp_offer_shoudong_price = formattedValue;
    if(Number(this.temp_offer_shoudong_price)>Number(this.payInfo.order_price)){
      this.$message.warning(`当前最高优惠金额${this.payInfo.order_price}元`)
    }
  }
  //手动输入折扣率
  private inputZhekouRate(value){
    value=value.replace(/[^0-9]/g,'')
    this.temp_offer_zhekou_rate = value;
    if(Number(this.temp_offer_zhekou_rate)>100){
      this.temp_offer_zhekou_rate = 100
    }
  }

  //根据折扣率计算折扣金额
  private setOfferZhekouPrice():void{
    this.offer_zhekou_price = ((100 - Number(this.offer_zhekou_rate)) * Number(this.payInfo.order_price) * 0.01).toFixed(2)

  }

  //键盘点击输入优惠
  private changeDiscount(value:string=''):void{
    if(value==='delete'){
      if(this.discountMode===1){
        this.temp_offer_shoudong_price = this.temp_offer_shoudong_price.slice(0, -1);
      }else{
        this.temp_offer_zhekou_rate = String(this.temp_offer_zhekou_rate)
        this.temp_offer_zhekou_rate = this.temp_offer_zhekou_rate.slice(0, -1);
      }
    }else{
      if(this.discountMode===1){
        if(this.temp_offer_shoudong_price.length===1&&this.temp_offer_shoudong_price==='0'){
          this.temp_offer_shoudong_price = value
        }else{
          this.temp_offer_shoudong_price += value
        }
      }else{
        if(this.temp_offer_zhekou_rate.length===1&&this.temp_offer_zhekou_rate==='0'){
          this.temp_offer_zhekou_rate = value
        }else{
          this.temp_offer_zhekou_rate += value
        }
        // this.setOfferZhekouPrice()
      }
    }

  }

  //确定键盘优惠价格
  private comfirmDiscount():void{
    if(this.discountMode===1){
      if(Number(this.temp_offer_shoudong_price)>Number(this.payInfo.order_price)){
        this.$message.warning(`当前最高优惠金额${this.payInfo.order_price}元`)
        return
      }
      const should_pay_price = Number(this.getShouldPayPrice()) - Number(this.temp_offer_shoudong_price)
      const math_abs = Math.abs(should_pay_price).toFixed(2)
      if(Number(should_pay_price)<0){
        this.$message.warning(`优惠金额超出${math_abs}元`)
        return
      }
      this.offer_shoudong_price = this.temp_offer_shoudong_price
      // this.should_pay_merge_price = Number(this.target_should_offer_price)  - Number(this.offer_shoudong_price)
    }else{

      const zhekou_price = ((100 - Number(this.temp_offer_zhekou_rate)) * Number(this.payInfo.order_price) * 0.01).toFixed(2)
      const should_pay_price = Number(this.getShouldPayPrice()) - Number(zhekou_price)
      console.log('should_pay_price',should_pay_price,Number(should_pay_price))
      const math_abs = Math.abs(should_pay_price).toFixed(2)
      if(Number(should_pay_price)<0){
        this.$message.warning(`优惠金额超出${math_abs}元`)
        return
      }
      this.offer_zhekou_rate = this.temp_offer_zhekou_rate
      this.setOfferZhekouPrice()
      // this.should_pay_merge_price = Number(this.target_should_offer_price)  - Number(this.offer_zhekou_price)
    }
    this.showDiscount = false
  }

  //重置抹零
  private resetOfferPrice():void{
    this.target_should_offer_price  = this.payInfo.total_price
    // this.should_pay_merge_price = this.payInfo.total_price
    this.temp_offer_shoudong_price = ''
    this.temp_offer_zhekou_rate = ''
    this.offer_fen_price = ''
    this.offer_jiao_price = ''
    this.offer_kuai_price = ''
    this.offer_shoudong_price = ''
    this.offer_zhekou_price = ''
  }

  //关闭页面
  private closePayment():void{
    this.showPayment = false
    this.order_id = ''
    this.pool_id = ''
    this.tableinfo = {}
    if(!this.setInterConsumeList){
      this.startCountConsumeList();
    }
  }

  //清空抹零
  private clearOfferPrice():void{
    this.target_should_offer_price  = ''
    // this.should_pay_merge_price = 0
    this.temp_offer_shoudong_price = ''
    this.temp_offer_zhekou_rate = ''
    this.offer_fen_price = ''
    this.offer_jiao_price = ''
    this.offer_kuai_price = ''
    this.offer_shoudong_price = ''
    this.offer_zhekou_price = ''
  }

  //时间差转分钟
  private calculateMinutesDifference(start_time:string,end_time:string=''):number {
    const start = moment(start_time, "YYYY-MM-DD HH:mm:ss");
    let end = ''
    if(end_time){
       end = moment(end_time, "YYYY-MM-DD HH:mm:ss");
    }else{
      end = moment();
    }
    const duration = moment.duration(end.diff(start));
    const minutes = duration.asMinutes();

    return Math.floor(minutes);
  }

  /**
   * 获取分，角，块
   */

  private getPriceComponents(value:number|string) {
    // 将值转换为数字
    let amount = parseFloat(value).toFixed(2);
    // 确保值是一个有效的数字
    if (isNaN(amount)) {
      return null;
    }
    const index = amount.indexOf('.');
    let secondDecimal = 0

    // 获取第二位小数的值（分）
    // let secondDecimal = Math.floor(amount * 100) % 10 / 100;
    // secondDecimal = secondDecimal.toFixed(2); // 格式化为两位小数

    if (index !== -1 && amount.length > index + 2) {
      // 返回小数点后第二位的字符
      secondDecimal =  `0.0${amount.charAt(index + 2)}`;
    }

    // 获取第一位小数的值（角）
    // let firstDecimal = Math.floor(amount * 10) % 10 / 10;
    // firstDecimal = firstDecimal.toFixed(2); // 格式化为两位小数

    let firstDecimal = 0
    if (index !== -1 && amount.length > index + 1) {
      // 返回小数点后第二位的字符
      firstDecimal =  `0.${amount.charAt(index + 1)}`;
    }

    // 获取个位上的值（元）
    let yuan = Math.floor(amount) % 10;
    yuan = yuan.toFixed(2); // 格式化为两位小数

    // 返回结果
    return {
      fen: parseFloat(secondDecimal).toFixed(2), // 转换为数字
      jiao: parseFloat(firstDecimal).toFixed(2), // 转换为数字
      kuai: parseFloat(yuan).toFixed(2) // 转换为数字
    };
  }
  private shopList(): void {
    // const type = this.$store.state.platformType
    this.businessApi.shopList({type: 0}, (res: any) => {
      if(res.data.length){
        this.shopInfo = res.data[0]
      }
    });
  }

  /*
  **自助服务单列表
  * */
  private onServiceRemind(pool:any):void{
    this.$refs.serviceListDialog.show(pool)
  }


  @Watch("showPayment")
  $setShowPayment(newValue:boolean): void {
    if(newValue){
      this.showCoach = false
    }
  }

  @Watch("showCoach")
  $setShowCoach(newValue:boolean): void {
    if(newValue){
      this.showPayment = false
    }
  }
}
