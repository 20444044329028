
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import GroupBuyingAPI from "@/common/api/groupBuying";
export const pageApiList = getPageAPIs(GroupBuyingAPI);
interface IaddForms {
  name:string,
  value:number|string,
  type:number|null,
  third_party_id:string,
  id?:string
}
const defaultAddForms:IaddForms = {
  name:'',
  value:'',
  type:null,
  third_party_id:''
}
@Component({})
export default class UserList extends Vue {
  private buyAddressApi: any = new GroupBuyingAPI();
  private sellShow: boolean = false;
  private addForms: any = {
   ...defaultAddForms
  };
  private form: any = {
    time: [], // 时间选择
  };

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.buyAddressApi.classifylists(
        {
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.total;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }

  private title: string = "";

  private addcoupons(type:string='add',row?: any): void {
    this.restForm();
    if (type === 'add') {
      this.title = "添加";
    } else {
      this.title = "编辑";
      this.addForms = {...row};
    }
    this.sellShow = true;
  }

  private submit(): void {
    if (this.title === "添加") {
      this.buyAddressApi.classifyadd(this.addForms, () => {
        this.sellShow = false;
        Util.showToast("添加成功");
        this.search();
      });
    } else {
      this.buyAddressApi.classifyedit(this.addForms, () => {
        this.sellShow = false;
        Util.showToast("修改成功");
        this.search();
      });
    }
  }

  private voucherdel(row:any): void {
    this.$confirm(`确认删除${row.name}吗？`, "删除提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      this.buyAddressApi.classifydelete({id: row.id}, () => {
        Util.showToast("删除成功");
        this.search();
      })
    })

  }

  private restForm(): void {
    this.addForms = {...defaultAddForms};
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}
