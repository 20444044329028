import {BasicsClass} from "@/common/BasicsClass";

export default class GoodsListAPIClass extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/goods/lists',
            name: 'list',
            label: '列表'
        }],
        ['category', {
            url: '/goods/category',
            name: 'goodsCategory',
            label: '商品分类'
        }],
        ['add', {
            url: '/goods/add',
            name: 'add',
            label: '添加'
        }],
        ['save', {
            url: '/goods/save',
            name: 'save',
            label: '修改'
        }],
        ['delete', {
            url: '/goods/delete',
            name: 'delete',
            label: '删除'
        }],
        ['ossSts', {
            url: '/oss/sts',
            name: 'ossSts',
            label: '上传'
        }],
    ])


    //商品列表
    public goodslists(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('getList').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //商品分类
    public goodscategory(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('category').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //添加商品
    public goodsadd(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('add').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //修改商品
    public goodssave(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('save').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //删除商品
    public goodsdelete(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('delete').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    //删除商品
    public ossSts(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('ossSts').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

}
