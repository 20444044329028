
import { Component, Vue } from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import AdvertisementConfigAPI from "@/common/api/advertisementConfig";
import { CommonApi } from "@/common/api/common";
export const pageApiList = getPageAPIs(AdvertisementConfigAPI);
function createPicItem(id: number, url: string) {
  const item = {
    id,
    url,
    href: url,
    type: "image/png",
    types: "1",
  };

  return item;
}
@Component({})
export default class UserList extends Vue {
  private advertisementConfig: any = new AdvertisementConfigAPI();
  private commonApi: any = new CommonApi();
  private add_form: any = {
    image_id: 0,
    sort: "",
    is_up:1
  };
  private form: any = {
    time: [], // 时间选择
  };
  private visible:boolean = false
  private avatar_images: any = [];
  private activated(): void {
    this.getTable();
  }
  private getTable(): void {
    this.advertisementConfig.list(
      {
        ...this.form,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
        this.tablePage.pageNo = res.data.page;
        this.tablePage.pageSize = res.data.prepage;
      }
    );
  }
  private operate_type:number = 1 //1新增，2编辑
  private onAddEditForm(type = 1,row={}) {
    this.visible = true
    this.operate_type = type
    this.add_form = new Util().clearObject(this.add_form);
    if(type===2){
      this.add_form = {...row}
      console.log('9999',this.add_form)
      this.avatar_images = row.image_url ? [createPicItem(row.image_id, row.image_url)] : [];
    }
  }

  private onDelete(row:any):void{
    this.$confirm(`确认删除吗？`, "删除提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      this.advertisementConfig.delete({id: row.id}, () => {
        Util.showToast("删除成功");
        this.getTable();
      })
    })
  }

  private onSwitch(row:any,is_up:number):void{
    this.advertisementConfig.editPart({
      id: row.id,
      is_up:is_up
    }, () => {
      Util.showToast("操作成功");
      this.getTable();
    })
  }

  private add_submit(): void {
    const params = {...this.add_form}
    const avatar_images = this.avatar_images
    if(avatar_images.length){
      params.image_id = avatar_images[0].id
    }else{
      params.image_id = ''
    }
    console.log('提交参数',params)
    if(this.operate_type===1){
      this.advertisementConfig.add(params, (res: any) => {
        this.$message.success('新增成功')
        this.visible = false
        this.getTable()
      });
      return;
    }
    if(this.operate_type===2){
      this.advertisementConfig.edit(params, (res: any) => {
        this.$message.success('编辑成功')
        this.visible = false
        this.getTable()
      });
      return;
    }

  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getTable();
  }

  private tableData: any[] = [];
  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };
  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;
    this.getTable();
  }
  private handleChangeUploadPic(file: any, fileList: any[]) {
    this.commonApi.getOss(
        file instanceof File ? file : file.raw,
        (res: any) => {
          const { data } = res;
          const { data: uploadImgObjWrap } = data;
          const { item: uploadImgObj } = uploadImgObjWrap;
          const { id, url } = uploadImgObj;
          this.$delete(this,'avatar_images')
          this.$set(this,'avatar_images',[createPicItem(id, url)])
        },
        () => {
          this.$message.error("图片上传失败");
        }
    );
  }

  private handleBeforePreviewPicture(row: any) {
    const avatar_images = this.avatar_images
    const previewItem: any = {
      images: avatar_images.map((pic: any) => pic.url),
      image_count: avatar_images.length
    }

    this.handlePreviewMedia(previewItem)
  }
  private handleFileRemove(file: any, mode: string) {
    this.$delete(this, 'avatar_images');
    this.$set(this, 'avatar_images', []);
  }
  private avatar_images: any = [];

  private handlePreviewMedia(row: any) {
    const { image_count, images } = row || {};

    const urls: any[] = [];

    if (image_count > 0) {
      urls.push(
          ...images.map((img: string) => {
            return {
              title: "",
              type: "image/jpeg",
              href: img,
              thumbnail: img,
            };
          })
      );
    }


    this.avatar_images = urls;
  }
}
