import {BasicsClass} from "@/common/BasicsClass";

export default class DeviceConfigAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/deviceBind/index',
            name: 'list',
            label: '绑定列表'
        }],
        ['bind', {
            url: '/deviceBind/bind',
            name: 'bind',
            label: '绑定'
        }],
        ['unbind', {
            url: '/deviceBind/unbind',
            name: 'unbind',
            label: '解绑'
        }],
        ['orderPool', {
            url: '/order/pool',
            name: 'order-pool',
            label: '台桌列表'
        }],
    ])


    public list(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('list').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public bind(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('bind').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public unbind(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('unbind').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //台桌列表
    public pool(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('orderPool').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }


}
