
// @ is an alias to /src
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import vTags from "@/components/Tags.vue";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { Login, LoginUserMerchants } from "@/common/login/login";
import businessAPI from "@/common/api/business";
import {mergeStrategyAddNewParams} from "@/common/autoCollectMenus";
import PermissionSetAPI from "@/common/api/permissionSet";
import bus from "@/common/bus/bus";
import goodsSale from "@/components/goodsSale.vue";
@Component({
  components: {
    "v-tags": vTags,
    goodsSale
  },
})
export default class HelloWorld extends Vue {

  private logout: Login;
  constructor() {
    super();
    this.logout = new Login();
    this.deepLoopUtil = new DeepLoop();
  }

  private options: any = [];
  private value: string = "";
  private timer: any = "";
  private businessApi: any = new businessAPI();
  private permissionSet: any = new PermissionSetAPI();
  private userMenus: any = JSON.parse(localStorage.getItem("menus") as string);
  private defaultActive: any = this.$route.name;
  private deepLoopUtil: any;
  private popover_visible:boolean = false
  private setSelect(id: string, url: string): void {
    if (this.defaultActive != id) {
      this.defaultActive = id;
      this.$router.push(url);
    }
  }
  private path_is_business:boolean = false
  private created() {
    this.shopList();
    this.getBusinessPendantList()
    bus.$on('pendant-reload',()=>{
      console.log('pendant-reload')
      this.getBusinessPendantList()
    })
    this.path_is_business =  this.$route.fullPath === '/business'
  }

  @Watch("$route")
  $routechange(newValue: string, oldValue: string) {
    return this.path_is_business =  newValue.fullPath === '/business'
  }

  private shopList(): void {
    // const type = this.$store.state.platformType
    this.businessApi.shopList({type: 0}, (res: any) => {
      this.options = res.data;
      for (let i of this.options) {
        if (
          i.cashier_url.split("=")[1].split("#")[0] ==
          localStorage.getItem("ShopId")
        ) {
          this.value = i.cashier_url;
          break;
        }
      }
    });
  }
  private sumbitBacthAddNewRightMenuLoading: boolean = false
  private time:number = 0
  private handleAutoBatchAddMenus() {
    this.time ++
    if(this.time<10){
      return
    }
    mergeStrategyAddNewParams().then((addlistParams)=>{
      console.log('新增上传菜单',addlistParams)
      const resultParams = JSON.stringify(addlistParams)

      this.sumbitBacthAddNewRightMenuLoading = true

      this.permissionSet.batchAddMenuList({
        data: resultParams
      }, () => {
        this.time = 0
        this.sumbitBacthAddNewRightMenuLoading = false
        this.$message.success('批量新增成功')
      }, () => {
        this.sumbitBacthAddNewRightMenuLoading = false
      })
    })
  }

  private onShowPopover():void{
    this.popover_visible = true
    this.getBusinessPendantList()
  }
  private pendantList:any[] = []
  private getBusinessPendantList(): void {
    this.businessApi.orderPendantIndex({}, (res: any) => {
      console.log('挂单列表',res)
      this.pendantList = res.data.list
    });
  }
  private onClickService():void{
    bus.$emit('home-service')
  }
  private toPay(item:any):void{
    this.popover_visible = false
    bus.$emit('pendant-pay',item)
  }

  private changeshop(): void {
    const ShopId: any = this.value.split("=")[1].split("#")[0];

    localStorage.setItem("ShopId", ShopId);
    window.location.href = this.value + "#/business";
  }

  // 用户名下拉菜单选择事件
  private handleCommand(command: string): void {
    if (command == "loginout") {
      clearInterval(this.timer);
      // this.logout.logout();
      localStorage.clear();
      this.$router.push("/login");
    }
  }
  // end

  @Watch("$route")
  $setMenusList(): void {
    this.defaultActive = this.$route.name;
  }
}
