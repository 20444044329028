import {businessRoutes} from '@/router/business'
import {memberRoutes} from '@/router/member'
import {goodsManageRoutes} from '@/router/goodsManage'
import {orderRoutes} from '@/router/order'
import {cardRoutes} from '@/router/card'
import {coachManageRoutes} from '@/router/coachManage'
import {dataStatisticsRoutes} from '@/router/dataStatistics'
import {revenueReportRoutes} from '@/router/revenueReport'
import {accountManageRoutes} from '@/router/accountManage'
import {shopConfigRoutes} from "@/router/shopConfig";

// import router from '@/router';

import { Message } from 'element-ui';

import PermissionSetAPI from "@/common/api/permissionSet";

export function getRightMenuConfig(mode: string = 'config'): Promise<any> {
  const permissionSetApi = new PermissionSetAPI()

  return new Promise((resolve, reject) => {
    switch (mode) {
      case 'config':
        permissionSetApi.getRightMenuTreeConfigList({}, (res: any) => {
          const {list} = res.data
          console.log('获取左侧菜单栏配置接口',list)
          if (Array.isArray(list) && list.length) {
            const newList = handleConfigMenuList(list)
            resolve({
              list: newList
            })
          } else {
            Message.error('该账户暂无任何权限，请联系管理员处理')
            resolve({
              list: []
            })
          }
        }, (err: any) => {
          reject(err)
        })
        break;
      case 'batch':
        permissionSetApi.getRightMenuTreeBatchList({}, (res: any) => {
          resolve(res.data)
        }, (err: any) => {
          reject(err)
        })
        break;
    }

  })

}

function handleConfigMenuList(list: any[]) {
  const newList: any[] = []

  for (const item of list) {
    const {
      id,
      menu_name,
      menu_url,
      is_record,
      is_show,
      menu_key,
      child
    } = item || {}
    const newItem: any = {
      id,
      menu_name,
      menu_url,
      is_record,
      is_show,
      menu_key,
    }


    if (Array.isArray(child)) {
      newItem.children = [
        ...child
      ]
    }


    newList.push(newItem)
  }

  return newList
}

export function getConfigMapList(configlist: any[], mode = 'custom-key', parent_ids: any[] = []) {
  const newConfigMapList: any = new Map()

  for (const item of configlist) {
    const {
      id,
      menu_name,
      menu_url,
      is_record,
      is_show,
      menu_key,
      children,
      child
    } = item || {}
    const newItem: any = {
      id,
      menu_name,
      menu_url,
      is_record,
      is_show,
      menu_key,
    }


    newItem.__parent_ids = parent_ids


    if (children && Array.isArray(children)) {
      newItem.children = getConfigMapList(children, mode, [
        ...(parent_ids || []),
        newItem.id
      ])
    } else if (child && Array.isArray(child)) {

      newItem.children = getConfigMapList(child, mode, [
        ...(parent_ids || []),
        newItem.id
      ])

    }

    switch (mode) {
      case 'custom-key':
        newConfigMapList.set(menu_key, newItem)
        break;
      case 'exist-id':
        newConfigMapList.set(id, newItem)
        break;
    }
  }

  return newConfigMapList
}

function handleDefaultRightMenuList(list: any[], mapList: Map<string, any>, level: number = 1) {
  function handleMenuCategory(list: any[]) {
    const newList: any[] = []
    for (const item of list) {
      // console.log(item, 'item menuCategory')

      // console.log(mapList.has(item.key), 'mapList.has(item.key)')

      const itemKey = item.key

      // console.log(itemKey, 'itemKey')
      if (mapList.has(itemKey)) {
        const configItem = mapList.get(itemKey)

        const {
          menu_name,
          children,
          child
          // menu_url,
          // is_record
        } = configItem || {}
        const newCategoryItem: any = {
          ...item,
          name: menu_name,
          // menu_url,
        }

        if (item.children && Array.isArray(item.children)) {
          newCategoryItem.children = handleDefaultRightMenuList(item.children, child || children, level + 1)
        }

        // console.log(newCategoryItem, 'newCategoryItem')


        newList.push(newCategoryItem)
      }
    }

    // console.log(newList, 'newList handleMenuCategory')

    return newList
  }

  function handlePageMenu(list: any[]) {
    const newList: any[] = []

    for (const item of list) {
      // console.log(mapList, 'mapList')
      // console.log(item, 'item')
      const configItem = mapList.get(item.meta.key)

      const {
        menu_name,
        menu_url,
        // is_show
      } = configItem || {}

      if (mapList.has(item.meta.key)) {
        const newPageItem: any = {
          ...item,
          path: menu_url
        }

        if (menu_name) {
          newPageItem.meta.title = menu_name
        }



        newList.push(newPageItem)
      }
    }

    // console.log(newList, 'newList handlePageMenu')


    return newList
  }
  switch (level) {
    case 1:

      return handleMenuCategory(list)
    case 2:

      return transformRoute2Children(handlePageMenu(list))
  }
}


async function filterStrategy(defaultList: any[], targetList: any[] = [], getConfigFunc: Function = () => Promise.resolve()) {
  const { list } = await getConfigFunc('config')

  console.log(list, 'list getConfigFunc')

  if (Array.isArray(list)) {
    if (list.length == 0) {
      // targetList.push(
      //   ...(handleDefaultRightMenuList(defaultList, new Map([])) || [])
      // )

      return
    }

    const mapList = getConfigMapList(list)

    // console.log(mapList, 'mapList')

    targetList.push(
      ...(handleDefaultRightMenuList(defaultList, mapList) || [])
    )
  }
}

function transformRoute2Children(routes: any[]): any[] {
  const children = routes.filter((route: any) => {
    const {
      meta
    } = route

    const {
      asideHidden
    } = meta

    return asideHidden != true
  }).map((route) => {
    const {
      meta
    } = route || {}

    const {
      title
    } = meta
    const child = {
      id: route.name,
      name: title || '',
      url: route.path
    }

    return child
  })

  return children
}

export const categorys2routeList = [
  businessRoutes,
  memberRoutes,
  goodsManageRoutes,
  orderRoutes,
  cardRoutes,
  coachManageRoutes,
  dataStatisticsRoutes,
  revenueReportRoutes,
  accountManageRoutes,
  shopConfigRoutes
]

export function getOriginMenuList(): any[] {
  const list = [
    {
      id: 1,
      icon: require("@/assets/side/business.png"),
      name: '台桌',
      key: 'business-directory',
      children: [],
      isNoCatalog:true
    },
    {
      id: 2,
      icon: require("@/assets/side/data.png"),
      name: '会员',
      key: 'member-directory',
      children: []
    },
    {
      id: 3,
      icon: require("@/assets/side/goods.png"),
      name: '商品',
      key: 'goods-manage-directory',
      children: []
    },
    {
      id: 4,
      icon: require("@/assets/side/goods.png"),
      name: '订单',
      key: 'order-directory',
      children: []
    },
    {
      id: 5,
      icon: require("@/assets/side/goods.png"),
      name: '卡券',
      key: 'card-directory',
      children: []
    },
    {
      id: 6,
      icon: require("@/assets/side/coach.png"),
      name: '助教',
      key: 'coach-manage-directory',
      children: []
    },
    {
      id: 7,
      icon: require("@/assets/side/data.png"),
      name: '数据',
      key: 'data-statistics-directory',
      children: []
    },
    {
      id: 8,
      icon: require("@/assets/side/report.png"),
      name: '营收报表',
      key: 'revenue-report-directory',
      children: []
    },
    {
      id: 9,
      icon: require("@/assets/side/account.png"),
      name: '账号',
      key: 'account-manage-directory',
      children: []
    },
    {
      id: 10,
      icon: require("@/assets/side/account.png"),
      name: '店铺配置',
      key: 'shop-config-directory',
      children: []
    },
  ]


  return  list
}

const categoryList = getOriginMenuList()


const defaultRightMenuList = categoryList.map((item, index) => {
  item.children.push(
    ...(categorys2routeList[index] || [])
  )

  return item
})

export function filterShowRouteStrategy(targetList: any[] = [], getConfigFunc: Function = () => Promise.resolve()) {
  return filterStrategy(defaultRightMenuList, targetList, getConfigFunc)
}

export default async function getMenuList(): Promise<any[]> {
  const token = localStorage.getItem('token')

  if (!token) return Promise.reject('未登录')

  const filterRightMenuList: any[] = []
  console.log('defaultRightMenuList',defaultRightMenuList)
  console.log('filterRightMenuList1111111111111',filterRightMenuList)
  await filterStrategy(defaultRightMenuList, filterRightMenuList, getRightMenuConfig)
  console.log('filterRightMenuList2222222222222',filterRightMenuList)

  return filterRightMenuList

  // return defaultRightMenuList
}

