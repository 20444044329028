
import businessApi from "@/common/api/business";
import {Component, Vue} from "vue-property-decorator";
const default_service_params = {
  is_status: 1,
  desk_id: ''
}
@Component({})
export default class CancelMergePools extends Vue {
  private visible: boolean = false
  private businessApi: any = new businessApi();
  private tableData: any = []
  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  }
  private pool_info: any = {}

  private service_params = {
   ...default_service_params
  }

  private show(pool_info:any={}): void {
    console.log('球桌信息', pool_info)
    this.service_params = { ...default_service_params}
    this.tableData = []
    this.tablePage.pageNo = 1
    if(pool_info.id){
      this.service_params.desk_id = pool_info.id
    }
    this.visible = true
    this.getTableList()
  }
  private loading:boolean = false
  private getTableList(): void {
    this.loading = true
    this.businessApi.serviceLists(
        {
          ...this.service_params,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
          this.loading = false
        }
    );
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;
    this.getTableList();
  }

  private comfirm(row: any) {
    this.businessApi.servicePass({
      id: row.id
    }, ((res: any) => {
      this.$message.success("撤销成功");
      this.getTableList()
    }))
  }

  private refuse(row: any) {
    this.$confirm(`确认撤销${row.pool_name}吗？`, "撤销提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      this.businessApi.servicePass({
        id: row.id
      }, ((res: any) => {
        this.$message.success("操作成功");
        this.getTableList()
      }))
    })
  }
}

