import {BasicsClass} from "@/common/BasicsClass";

export default class AdvertisementConfigAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/banner/list',
            name: 'list',
            label: '列表'
        }],
        ['add', {
            url: '/banner/add',
            name: 'bind',
            label: '添加'
        }],
        ['edit', {
            url: '/banner/edit',
            name: 'edit',
            label: '修改'
        }],
        ['delete', {
            url: '/banner/delete',
            name: 'delete',
            label: '删除'
        }],
        ['editPart', {
            url: '/banner/editPart',
            name: 'editPart',
            label: '部分字段'
        }],
    ])


    public list(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('list').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public add(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('add').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public edit(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('edit').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public delete(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('delete').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public editPart(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('editPart').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }


}
