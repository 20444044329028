export const memberRoutes = [
    {
        path: "/card_opening_history",
        name: "card_opening_history",
        meta: {
            title: "会员开通",
            key: 'card-opening-history',
            pageApisPrm: import("@/views/business/cardOpeningHistory/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/cardOpeningHistory/Index.vue"),
    },
    {
        path: "/singleStoreCardGive",
        name: "singleStoreCardGive",
        meta: {
            title: "会员赠送",
            key: 'single-store-card-give',
            pageApisPrm: import("@/views/business/singleStoreCardGive/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () =>
            import("@/views/business/singleStoreCardGive/Index.vue"),
    },

    {
        path: "/singleStoreCardDeduction",
        name: "singleStoreCardDeduction",
        meta: {
            title: "会员扣款",
            key: 'single-storeCard-deduction',
            pageApisPrm: import("@/views/business/singleStoreCardDeduction/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/singleStoreCardDeduction/Index.vue"),
    },
    {
        path: "/card_fee",
        name: "card_fee",
        meta: {
            title: "会员消费",
            key: 'card-fee',
            pageApisPrm: import("@/views/business/cardFee/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/cardFee/Index.vue"),
    },
    {
        path: "/card_management",
        name: "card_management",
        meta: {
            title: "会员用户",
            key: 'card-management',
            pageApisPrm: import("@/views/business/cardManagement/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/cardManagement/Index.vue"),
    },
    {
        path: "/single_card_transfer_record",
        name: "single_card_transfer_record",
        meta: {
            title: "单店会员移植记录",
            key: 'single-card-transfer-record',
            pageApisPrm: import("@/views/business/singleCardTransferRecord/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/singleCardTransferRecord/Index.vue"),
    },
]